import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faLongArrowAltLeft, faTimes, faUserMd, faHospitalUser } from '@fortawesome/free-solid-svg-icons'
import { get } from 'lodash'

import {
  ModalWindow,
  Body,
  Header,
  IconBlock,
  Close,
  TitleBlock,
} from './BookingModalStyled'

import { useBooking } from './BookingFormProvider'
import { useRouter } from 'next/router'
import BookingModalSteps from './BookingModalSteps'

let eventsSent = []
const _gaEvent = (e) => {
  if (typeof window !== 'undefined' && typeof gtag !== 'undefined') {
    const ej = JSON.stringify(e)
    if (eventsSent.indexOf(ej) === -1) {
      eventsSent.push(ej)
      gtag('event','click',e);
    }
  }
}

function BookingModal ({
  initialClinicId,
  onClose,
  setBottomBarContent,
  // timezone,
  address: initialAddress = null,
  location = null,
  phone = null,
  ...props
}) {
  const {
    suburbs = [],

    clinic,

    setClinic,
    setGroup,
    setPractitioner,
    setTime,

    suburb: bookingSuburb,
    submit: bookingSubmit,
    practitioners,
    time,
    state,
  } = useBooking()
  const [submitted, setSubmitted] = React.useState(null)

  const close = () => {
    onClose()
    if (submitted) setGroup(null)
    setSubmitted(null)
  }

  const address = React.useMemo(() => {
    if (initialAddress) return initialAddress
    return clinic && clinic.address
  }, [initialAddress, clinic])

  const gaEvent = (e) => props.isOpen && _gaEvent(e)

  const { asPath } = useRouter()

  const submit = (data) => {
    setSubmitted(data)
    const practitioner = (practitioners || []).filter(p => p.id.toString() === time.practitionerIds[0].toString())[0]
    window.localStorage.setItem('booking-complete', JSON.stringify({
      data,
      address,
      location,
      phone,
      timezone: get(bookingSuburb, 'timezone', 'Australia/Sydney'),
      time: time.ts,
      clinicName: clinic.name,
      practitioner,
    }))
    if (process.env.REACT_APP_APP_STAGE !== 'local') {
      data.page = asPath
      bookingSubmit(data).then(() => {
        gaEvent({
          event_category: 'Booking',
          event_action: 'step',
          event_label: 'Success',
          event_value: 5,
        })
        gaEvent({
          event_category: 'Booking',
          event_action: 'submit',
        })
        setTimeout(() => {
          window.location.href = '/booking-complete'
        }, 500)
        // onClose()
      })
    } else {
      setTimeout(() => {
        window.location.href = '/booking-complete'
      }, 1000)
    }
  }

  const {
    formStep,
    icon,
    back,
    title,
  } = React.useMemo(() => {
    if (!clinic || !clinic.id) return {
      title: 'Please, select your suburb',
      icon: faCalendar,
      back: null,
      formStep: -1,
    }
    if (!state.groups) return {
      title: 'Appointment booking',
      icon: faCalendar,
      back: suburbs.length ? () => setClinic(null) : null,
      formStep: 0,
    }
    if (state.group === null) return {
      title: 'Appointment type',
      icon: faCalendar,
      back: null,
      formStep: 1,
    }
    if (state.group === 'repeating' && state.practitioner === null) return {
      title: 'Select practitioner',
      icon: faUserMd,
      back: () => setGroup(null),
      formStep: 2,
    }
    if (!state.time) return {
      title: 'Select time',
      back: () => {
        if (!initialClinicId) return setClinic(null)
        return (state.group === 'repeating') ? setPractitioner(null) : setGroup(null)
      },
      icon: faCalendar,
      formStep: 3,
    }
    if (!submitted) return {
      title: 'Patient data',
      icon: faHospitalUser,
      back: () => setTime(null),
      formStep: 4,
    }
    return {
      title: 'Appointment booked',
      icon: faHospitalUser,
      back: null,
      formStep: 99,
    }
  }, [clinic, state, submitted])

  return (
    <ModalWindow id={'booking-modal'} size={'lg'} {...props}>
      <Body>
        <Header tag={'div'}>
          { back && <IconBlock clickable onClick={() => back()}><FontAwesomeIcon icon={faLongArrowAltLeft}/></IconBlock> }
          <IconBlock><FontAwesomeIcon icon={icon}/></IconBlock>
          <TitleBlock>
            <div className={'title'}>{title} {process.env.REACT_APP_APP_STAGE === 'local' ? `(Local) ${get(bookingSuburb, 'timezone', 'Australia/Sydney')}` : ''}</div>
            <div className={'clinicName'}>{clinic ? clinic.name : 'Local Physio'}</div>
          </TitleBlock>
          <Close clickable onClick={() => close()}>
            <FontAwesomeIcon icon={faTimes}/>
          </Close>
        </Header>
        <BookingModalSteps
          step={formStep}
          setBottomBarContent={setBottomBarContent}
          clinic={clinic}
          suburb={bookingSuburb}
          onSubmit={submit}
          gaEvent={gaEvent}
					url={state.url}
        />
      </Body>
    </ModalWindow>
  )
}

export default BookingModal
