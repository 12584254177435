import React from 'react'
import Row from '@bootstrap-styled/v4/lib/Row'
import { Content, FormColumn } from './BookingModalStyled'
import Loading from '../Loading'
import { get } from 'lodash'
import UserDataInput from './UserDataInput'

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
const SuburbSelectionStep = dynamic(() => import('./SuburbSelectionStep'), {
  ssr: false,
})
// import SuburbSelectionStep from './SuburbSelectionStep'
const AppointmentTypeStep = dynamic(() => import('./AppointmentTypeStep'), {
  ssr: false,
})
const PractitionersStep = dynamic(() => import('./PractitionersStep'), {
  ssr: false,
})
const CalendarStep = dynamic(() => import('./CalendarStep'), {
  ssr: false,
})

function BookingModalSteps ({
  step,
  gaEvent,
  setBottomBarContent,
  clinic,
  suburb,
  onSubmit,
	url
}) {

  const { asPath } = useRouter()

  return <Content>
    { (step === -1) && (
      <SuburbSelectionStep
        gaEvent={gaEvent}
      />
    )}
    { (step === 0) && (
      (clinic.provider === 'typeform') ? (
        <Row>
          <FormColumn fullHeight={true} xs={12} lg={12} dangerouslySetInnerHTML={{
            __html: `<iframe src="https://localphysionetwork.typeform.com/to/XHijQoo4#suburb=${clinic.providerData.name}&state=${clinic.providerData.state}&postcode=${clinic.providerData.postcode}&page=${encodeURI(asPath)}" width="100%" height="550" frameborder="0"></iframe>`,
          }}>
          </FormColumn>
        </Row>
      ) : (clinic.provider === 'IFRAME') ? (
				<Row>
          <FormColumn fullHeight={true} xs={12} lg={12} dangerouslySetInnerHTML={{
            __html: `<iframe src="${url}" width="100%" height="550" frameborder="0"></iframe>`,
          }}>
          </FormColumn>
        </Row>
			) : (
        <Row>
          <FormColumn xs={12} lg={12}>
            <Loading/>
          </FormColumn>
        </Row>
      )
    )}
    { (step === 1) && <AppointmentTypeStep gaEvent={gaEvent}/> }
    { (step === 2) && <PractitionersStep gaEvent={gaEvent}/> }
    { (step === 3) && <CalendarStep timezone={get(suburb, 'timezone', 'Australia/Sydney')} gaEvent={gaEvent}/> }
    { (step === 4) && (
      <Row>
        <FormColumn fullHeight xs={12} lg={{ size: 10, offset: 1, }}>
          <UserDataInput
            setBottomBarContent={setBottomBarContent}
            onSubmit={onSubmit}
          />
        </FormColumn>
      </Row>
    )}
  </Content>
}

export default BookingModalSteps
