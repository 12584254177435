import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const F = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  .spinner {
    font-size: 40px;
    color: ${props => props.theme['$colors']['blue']};
  }
`

function Loading () {
  return <F>
    <div className={'spinner'}>
      <FontAwesomeIcon icon={faSpinner} spin/>
    </div>
    <div>Loading...</div>
  </F>
}

export default Loading
