import Row from '@bootstrap-styled/v4/lib/Row'
import Col from '@bootstrap-styled/v4/lib/Col'
import Form from '@bootstrap-styled/v4/lib/Form'
import FormText from '@bootstrap-styled/v4/lib/Form/FormText'
import Label from '@bootstrap-styled/v4/lib/Label'
import Option from '@bootstrap-styled/v4/lib/Option'
import A from '@bootstrap-styled/v4/lib/A'
import Button from '../Button'
import { useForm } from 'react-hook-form'
import React from 'react'
import InputMask from 'react-input-mask';
import moment from 'moment'
import styled from 'styled-components'

const insuranceProviders = [
  'Medibank',
  'BUPA',
  'HCF',
  'NIB',
  'AHM',
  'HBF',
  'Australian Unity',
  'GMHBA',
  'Teachers Health',
  'Defence Health',
  'Frank',
  'CBHS',
  'HIF',
  'Latrobe',
  'Westfund',
  'Health.com.au',
  'Health Partners',
  'TUH',
  'Peoplecare',
  'CUA Health',
  'GU Health Corporate',
  'HCI',
  'Phoenix',
  'Transport Health',
  'Nurses and Midwives',
  'Reserve Bank',
  'RT Health Fund',
  'CBHS Corporate',
  'CDH',
  'MDHF',
  'MyOwn Health',
  'Onemedifund',
  'QCH',
  'St Lukes',
  'ACA',
  'Doctors’ Health',
  'Emergency Services',
  'Navy Health',
  'Police Health',
]

const UserForm = styled(Form)`
  @media(max-width: 920px) {
    padding-bottom: 80px;
  }
`

const ButtonBlock = styled('div')`
  margin-top: 20px;
  .btn {
    padding: 14px 16px;
  }
`

function UserDataInput ({ onSubmit, setBottomBarContent }) {
  const storedUser = window.localStorage.getItem('userData')
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      ...(storedUser ? JSON.parse(storedUser) : {}),
      terms: undefined,
      remember: '1',
    },
  })

  React.useEffect(() => {
    setBottomBarContent(<Button form={'user-data-form'} block>Confirm appointment</Button>)
    return () => setBottomBarContent(null)
  }, [])

  const terms = <A href={'/legal/terms-and-conditions'} target={'_blank'}>terms of use</A>
  const privacy = <A href={'/legal/privacy-policy'} target={'_blank'}>privacy policy</A>

  return (
    <UserForm id={'user-data-form'} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={12} lg={6}>
          <div className={'form-group'}>
            <Label>First name</Label>
            <input
              ref={register({ required: true })}
              className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
              type={'text'}
              name={'firstName'}
            />
            { errors.firstName && <FormText color={'danger'}>Please, enter your first name</FormText> }
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className={'form-group'}>
            <Label>Last name</Label>
            <input
              type={'text'}
              name={'lastName'}
              ref={register({ required: true })}
              className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
            />
            { errors.lastName && <FormText color={'danger'}>Please, enter your last name</FormText> }
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <div className={'form-group'}>
            <Label>Email</Label>
            <input
              type={'email'}
              name={'email'}
              ref={register({ required: true })}
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            />
            { errors.email && <FormText color={'danger'}>Please, enter a valid email</FormText> }
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className={'form-group'}>
            <Label>Mobile phone</Label>
            <input
              type={'text'}
              name={'phone'}
              ref={register({ required: true })}
              className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
            />
            { errors.phone && <FormText color={'danger'}>Please, enter a valid phone number</FormText> }
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <div className={'form-group'}>
            <Label>Date of birth</Label>
            <InputMask
              mask="99/99/9999"
              ref={register({
                required: true,
                validate: v => !isNaN(moment(v, 'DD/MM/YYYY').unix()),
              })}
              placeholder={'Day/Month/Year'}
              name={'dateOfBirth'}
              className={`form-control ${errors.age ? 'is-invalid' : ''}`}
            />
            { errors.dateOfBirth && <FormText color={'danger'}>Please, enter a valid age</FormText> }
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className={'form-group'}>
            <Label>Gender</Label>
            <select
              name={'gender'}
              ref={register()}
              className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
            >
              <Option value={''}/>
              <Option value={'Male'}>Male</Option>
              <Option value={'Female'}>Female</Option>
              <Option value={'Not specified'}>I'd rather not say</Option>
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className={'form-group'}>
            <Label>Do you have a health insurance?</Label>
            <select
              className={'form-control'}
              name={'insurance'}
              ref={register()}>
              <option value={''}/>
              <option value={false}>I don't have health insurance</option>
              {insuranceProviders.map((v, i) => <option key={`insurance-${i}`} value={v}>{v}</option>)}
              <option value={null}>I don't have health insurance</option>
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={12}>
          <div className={'form-group'}>
            <div className="form-check">
              <input
                className={'form-check-input'}
                type={'checkbox'}
                name={'terms'}
                value={'1'}
                id={'check-terms'}
                ref={register({ required: true })}
              />
              <label className="form-check-label" htmlFor="check-terms">
                I accept {terms} and {privacy}
              </label>
            </div>
            { errors.terms && <FormText color={'danger'}>To proceed, you need to accept terms of use</FormText> }
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={12}>
          <div className={'form-group'}>
            <div className="form-check">
              <input
                className={'form-check-input'}
                type={'checkbox'}
                name={'remember'}
                value={'1'}
                id={'check-remember'}
                ref={register()}
              />
              <label className="form-check-label" htmlFor="check-remember">
                Remember my details for next time
              </label>
            </div>
          </div>
        </Col>
      </Row>
      <ButtonBlock className={'form-group d-none d-sm-block'}>
        <Button block>Confirm appointment</Button>
      </ButtonBlock>
    </UserForm>
  )
}

export default UserDataInput
